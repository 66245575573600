<template>
  <div>
    <ayl-berad-nav :nav="nav"></ayl-berad-nav>
    <div class="content-box mt20px">
      <div class="content-main">
        <el-form
          :model="form"
          ref="form"
          :inline="true"
          :label-position="$config.labelPosition"
          :label-width="$config.labelWidth"
        >
          <div class="form-label-base">作业规则</div>
          <el-form-item label="标段名称：">
            <span>{{ form.bidName }}</span>
          </el-form-item>
          <el-form-item label="监管单位：">
            <span>{{ form.bidSuperviseUnit }}</span>
          </el-form-item>
          <el-form-item label="养护企业：">
            <span>{{ form.bidMaintainUnit }}</span>
          </el-form-item>
        </el-form>
      </div>
      <div class="table-w mt24px">
        <label for class="table-label">未分配规则</label>
        <el-table
          :data="undistributedTable"
          style="width: 100%"
          empty-text="暂无数据"
          @selection-change="removeAllotRules"
        >
          <el-table-column type="selection" width="55"></el-table-column>
          <el-table-column type="index" label="序号"></el-table-column>
          <el-table-column label="规则类别" prop="regulationTypeDes"></el-table-column>
          <el-table-column label="规则名称" prop="regulationName"></el-table-column>
          <el-table-column label="报警等级" prop="alarmLevelDes"></el-table-column>
          <el-table-column label="创建人" prop="creator"></el-table-column>
          <el-table-column label="更新时间" width="140" prop="gmtModified">
            <template slot-scope="scope">{{scope.row.gmtModified| str2ymdhm}}</template>
          </el-table-column>
        </el-table>
      </div>
      <div class="transfer">
        <div :class="idListR.length === 0 ? 'icon' : 'icon-active'" @click="removeRules">
          <i class="el-icon-back"></i>
        </div>
        <div :class="idListL.length === 0 ? 'icon' : 'icon-active'" @click="addtRules">
          <i class="el-icon-right"></i>
        </div>
      </div>
      <div class="table-w mt24px">
        <label for class="table-label">已分配规则</label>
        <el-table
          :data="distributedTable"
          style="width: 100%"
          empty-text="暂无数据"
          @selection-change="addAllotRules"
        >
          <el-table-column type="selection" width="55"></el-table-column>
          <el-table-column type="index" label="序号"></el-table-column>
          <el-table-column label="规则类别" prop="regulationTypeDes"></el-table-column>
          <el-table-column label="规则名称" prop="regulationName"></el-table-column>
          <el-table-column label="报警等级" prop="alarmLevelDes"></el-table-column>
          <el-table-column label="创建人" prop="creator"></el-table-column>
          <el-table-column label="更新时间" width="140" prop="gmtModified">
            <template slot-scope="scope">{{scope.row.gmtModified| str2ymdhm}}</template>
          </el-table-column>
        </el-table>
      </div>

      <div class="tac mt40px">
        <el-button type="success" class="btn-success-hw" @click="submitForm()">保存</el-button>
        <el-button type="danger" class="btn-danger" style="margin-left: 10px" @click="onClose()">关 闭</el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    const vm = this;
    return {
      nav: [
        {
          name: "分配规则"
        }
      ],
      submitLoading: false,
      form: {
        bidName: vm.$route.query.bidName,
        bidSuperviseUnit: vm.$route.query.bidSuperviseUnit,
        bidMaintainUnit: vm.$route.query.bidMaintainUnit
      },

      // 未分配表格
      undistributedTable: [],
      tableDataL: [],
      idListL: [],
      // 已分配表格
      distributedTable: [],
      tableDataR: [],
      idListR: []
    };
  },
  methods: {
    //未分配表格勾选事件
    removeAllotRules(val) {
      this.tableDataL = val;
      this.idListL = val.map(item => {
        return item.regulationId;
      });
    },
    //已分配表格勾选事件
    addAllotRules(val) {
      this.tableDataR = val;
      this.idListR = val.map(item => {
        return item.regulationId;
      });
    },

    // 数据往左移动
    removeRules() {
      this.distributedTable = this.distributedTable.filter(item => {
        if (!new Set(this.idListR).has(item.regulationId)) {
          return item;
        }
      });
      this.undistributedTable = [
        ...this.undistributedTable,
        ...this.tableDataR
      ];
    },
    // 数据往右移动
    addtRules() {
      this.undistributedTable = this.undistributedTable.filter(item => {
        if (!new Set(this.idListL).has(item.regulationId)) {
          return item;
        }
      });
      this.distributedTable = [...this.distributedTable, ...this.tableDataL];
    },
    //关闭
    onClose() {
      BUS.$emit(BUSEVENT.REFRESH_OPERATING_RULES);
      BUS.$emit(BUSEVENT.CLOSE_NAV, this.$route);
    },

    async submitForm() {
      let params = {
        bidId: this.$route.query.id,
        regulationIdList: this.distributedTable.map(item => {
          return item.regulationId;
        })
      };
      await this.$api_hw
        .operationSafetyManagement_listRegulationAllocationUpdateForRegulation(
          params
        )
        .then(res => {
          this.$notify({
            title: "成功",
            message: "分配成功",
            type: "success"
          });
          this.onClose();
        });
    }
  },
  async mounted() {
    let data = await this.$api_hw.operationSafetyManagement_regulationInfoGetRegulationInfoListForAllocation(
      {
        bidId: this.$route.query.id,
        currentPage: 1,
        pageSize: null
      }
    );
    console.log(data);
    // 未分配
    this.undistributedTable = data.unassigned;
    // 已分配
    this.distributedTable = data.assigned;
  }
};
</script>

<style lang='sass' scoped>
.table-w
  width: 45%
  display: inline-block
  vertical-align: middle
  .table-label
    line-height: 46px
    font-size: 16px
    color: rgba(46,48,51,1)
.transfer
  width: 10%
  display: inline-block
  vertical-align: middle
  .icon
    width: 32px
    height: 32px
    background: #AAADB3
    border-radius: 21px
    margin: 10px auto
    i
      text-align: center
      font-size: 22px
      line-height: 32px
      width: 30px
      color: #fff
  .icon-active
    width: 32px
    height: 32px
    background: $-color-primary-3
    border-radius: 21px
    margin: 10px auto
    i
      text-align: center
      font-size: 22px
      line-height: 32px
      width: 30px
      color: #fff
        
.form-label-base
  border-left: 2px solid $-color-primary-3
  padding-left: 8px
</style>